.Spinner {
  height: 28px;
  left: 50%;
  margin-left: -14px;
  position: absolute;
  top: 45%;
  width: 28px;
  /* Spins external container */
  -webkit-animation: container-rotate 1600ms linear infinite;
  animation: container-rotate 1600ms linear infinite;
}

.Spinner__container {
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.04);
}

.Spinner__container .Spinner {
  position: static;
  margin: 0;
}

.Spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.Spinner-line--1 {
  border-color: #00b7af;
  -webkit-animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    line-1-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    line-1-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Spinner-line--2 {
  border-color: #2d559e;
  -webkit-animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    line-2-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    line-2-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Spinner-line--3 {
  border-color: #273f59;
  -webkit-animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    line-3-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    line-3-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Spinner-line--4 {
  border-color: #ccd1d8;
  -webkit-animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1)
      infinite both,
    line-4-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both,
    line-4-fade-in-out 4800ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Spinner-line-cog {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.Spinner-line-cog-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 200%;
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
}

.Spinner-line-cog-inner--left {
  border-right-color: transparent;
  -webkit-transform: rotate(129deg);
  -ms-transform: rotate(129deg);
  transform: rotate(129deg);
  -webkit-animation: left-spin 1200ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1200ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Spinner-line-cog-inner--right {
  left: -100%;
  border-left-color: transparent;
  -webkit-transform: rotate(-129deg);
  -ms-transform: rotate(-129deg);
  transform: rotate(-129deg);
  -webkit-animation: right-spin 1200ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  animation: right-spin 1200ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Spinner-line-cog-inner--center {
  width: 1000%;
  left: -450%;
}

.Spinner-line-ticker {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}
@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}
@-webkit-keyframes left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}
@keyframes left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}
@-webkit-keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}
@keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}
@-webkit-keyframes line-1-fade-in-out {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
@keyframes line-1-fade-in-out {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes line-2-fade-in-out {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes line-2-fade-in-out {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@-webkit-keyframes line-3-fade-in-out {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@keyframes line-3-fade-in-out {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@-webkit-keyframes line-4-fade-in-out {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes line-4-fade-in-out {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
