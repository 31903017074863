.Grid,
.c-Grid {
  display: flex;
  margin: 0 -10px;
  margin-top: 20px;
  clear: both;
}

.Grid__cell,
.c-Grid__cell {
  flex: 1;
  margin: 0 10px;
}

.Grid--fill .Grid__cell,
.c-Grid--fill .c-Grid__cell {
  display: flex;
}

.Grid__cell--column,
.c-Grid__cell--column {
  display: flex;
  flex-flow: column nowrap;
}

.c-Grid__cell--column > * {
  flex: 1;
}

.Grid--first,
.c-Grid--first {
  margin-top: 0;
}

.Grid.wrap,
.Grid--wrap,
.c-Grid--wrap {
  flex-wrap: wrap;
}

.Grid.wrap .Grid__cell {
  margin-top: 10px;
  margin-bottom: 10px;
}

/**
 * Specify the size of the cells to prevent overflows
 */

.c-Grid__cell.one-of-one {
  flex: 0 calc(100% - 20px);
}

.c-Grid__cell.one-of-two {
  flex: 0 calc(50% - 20px);
}

.c-Grid__cell.one-of-two.force-width {
  max-width: calc(50% - 20px);
}

.c-Grid__cell.one-of-three {
  flex: 0 calc(33.33% - 20px);
}
