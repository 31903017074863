.c-Tag {
  display: inline-flex;
  box-sizing: border-box;
  padding: 0 10px;
  height: 26px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
}

.c-Tag * {
  box-sizing: border-box;
}

.c-Tag--is-filter {
  display: inline-flex;
  margin-right: 8px;
  padding: 5px 5px 0 10px;
  height: 26px;
}

.c-Tag__btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  width: 23px;
  outline: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0.15);
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 25px;
}

.c-Tag__btn:hover,
.c-Tag__btn:active {
  background-color: rgba(0, 0, 0, 0.4);
}

.c-Tag.has-button {
  position: relative;
  padding-right: 28px;
}

.c-Tag.is-compact {
  padding: 0 5px;
  height: 20px;
  line-height: 19px;
  text-transform: uppercase;
}

.c-Tag.is-compact.has-button {
  padding-right: 20px;
}

.c-Tag.is-compact.has-button .c-Tag__btn {
  width: 15px;
  line-height: 19px;
}

.c-Tag.has-icon {
  position: relative;
  padding-left: 36px;
}

.c-Tag.is-separator {
  padding: 0;
}

.c-Tag.is-separator > div {
  margin-right: unset;
  left: unset;
  padding: 0 10px;
}

.c-Tag__icon {
  background-color: rgba(0, 0, 0, 0.15);
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 28px;
}

.c-Tag__icon svg {
  width: 16px;
  height: 16px;
}

.c-Tag.has-rounded-corners {
  border-radius: 2px;
}
