@import '~normalize.css/normalize.css';

@import '@fontsource/source-sans-pro';
@import '@fontsource/source-sans-pro/600.css';
@import '@fontsource/source-sans-pro/700.css';

@import './deprecated/deprecated-grid.css';

:root {
  --main-content-width: 1950px;
  --main-content-top-gap: 28px;
}

html,
input,
select {
  font-family: 'Source Sans Pro', sans-serif;
}

html,
body {
  box-sizing: border-box;
}

html {
  background-color: #f2f4f7 !important;
  height: 100%;
}

body {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

hr {
  border: none;
  height: 1px;
  width: 100%;
  background-color: var(--color-smoky);
  margin: 0;
}

hr.mg-top {
  margin-top: 40px;
}

hr.mg-bottom {
  margin-bottom: 40px;
}

#sentisis-app {
  height: 100vh;
  isolation: isolate;
}

.l-Wrapper {
  min-width: 1000px;
  margin: 10px 20px;
}

.c-Button {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  border-style: none;
  border-radius: 2px;
  appearance: none;
}

.region {
  stroke-width: 0.5px;
  stroke: #a5afba;
}
.global-content-wrapper {
  padding: 84px 32px 0 32px;
  min-width: 1100px;
  max-width: var(--main-content-width);
}

.mb-1 {
  margin-bottom: calc(4px * 1) !important;
}

.mb-2 {
  margin-bottom: calc(4px * 2) !important;
}

.mb-3 {
  margin-bottom: calc(4px * 3) !important;
}

.mb-4 {
  margin-bottom: calc(4px * 4) !important;
}

.mb-5 {
  margin-bottom: calc(4px * 5) !important;
}

.mb-6 {
  margin-bottom: calc(4px * 6) !important;
}

.mb-7 {
  margin-bottom: calc(4px * 7) !important;
}

.mb-8 {
  margin-bottom: calc(4px * 8) !important;
}

.mb-9 {
  margin-bottom: calc(4px * 9) !important;
}

.mb-10 {
  margin-bottom: calc(4px * 10) !important;
}
.mb-11 {
  margin-bottom: calc(4px * 11) !important;
}
.mb-12 {
  margin-bottom: calc(4px * 12) !important;
}
