.c-TagsInput {
  box-sizing: border-box;
}

.c-TagsInput * {
  box-sizing: border-box;
}

.c-TagsInput__field {
  position: relative;
  overflow: hidden;
}

/**
 * 1. If you change this you explode everything.
 */
.c-TagsInput__input {
  background-color: white;
  width: 100%;
  min-height: 45px;
  color: var(--color-dark_gray);
  border-radius: 1px;
  background-color: white;
  border: 1px solid var(--color-smoky);
  padding: 8.5px 10px 4.5px 10px; /* 1 */
  border-radius: 1px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  cursor: text;
}

.c-TagsInput__input .c-Tag {
  margin-right: 5px;
  margin-bottom: 5px;
}

.c-TagsInput__tag__text {
  margin: 0;
}

/**
 * Visually hide the input. It must still be rendered for the select all to work
 * There are more properties that necessarey just to make sure the user can't
 * interact with the elemnt
 */
.c-TagsInput__visually-hidden {
}

.c-TagsInput__input.is-focused {
  background-color: var(--color-cloudy);
  border-color: var(--color-light_gray);
}

.c-TagsInput__input.is-error {
  border-color: var(--color-sentiment_negative);
}

.c-TagsInput__input.is-selected {
  /* selection in progress */
}

.c-TagsInput__text {
  font-size: 14px;
  font-weight: 600;
  display: block;
  outline: none;
  border: 0;
  line-height: 1.5;
  width: 0;
  background-color: transparent;
  margin-top: 0;
  margin-right: 0;
  margin-left: 5px;
  margin-bottom: 5px;
  padding: 0;
}

.c-TagsInput__text:first-child {
  margin-left: 10px;
}

/**
 * 1. Same line-height as the tag so they look centered and more-or-less take
 *    up the same space
 */
.c-TagsInput__textarea {
  outline: none;
  background-color: transparent;
  display: flex;
  flex: 1;
  border: 0;
  line-height: 25px; /* 1 */
  font-size: 14px;
  font-weight: 600;
  resize: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  color: #4a637c;
}

.c-TagsInput .c-Tag:not(:last-child) {
  margin-right: 5px;
}

.c-TagsInput__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-gray);
  margin-bottom: 3px;
}

.c-TagsInput__label.is-error {
  color: var(--color-sentiment_negative);
}

.c-TagsInput__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

.c-TagsInput__header-buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  bottom: 0;
  gap: 4px;
  padding-bottom: 4px;
}

.c-TagsInput__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}

.c-TagsInput__copy-btn {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: var(--color-mermaid);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  outline: none;
}

.c-TagsInput__copy-btn:hover {
  text-decoration: underline;
}

.c-TagsInput__error {
  color: var(--color-sentiment_negative);
  font-size: 12px;
  line-height: 1.5;
}

.c-TagsInput__error-paragraph {
  display: block;
}

.c-TagsInput__placeholder {
  font-size: 14px;
  color: var(--color-light_gray);
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.c-TagsInput__help-text {
  color: var(--color-light_gray);
  margin-left: 7px;
}
