/* Taking into account the toast width - screen + half of the sidebar */
.c-Toast {
  position: fixed;
  bottom: 25px;
  left: calc(50% - 250px); /*1*/
  box-sizing: border-box;
  padding: 13px 20px;
  width: 600px;
  min-height: 45px;
  border-radius: 3px;
  background-color: var(--color-gray);
  z-index: 999;
}

.c-Toast--is-warning {
  background-color: #fbae01;
  top: 75px;
  bottom: auto;
  left: auto;
  right: 1em;
  animation-name: none !important;
}

.c-Toast--is-danger {
  background-color: #fb2323;
}

.c-Toast--is-error {
  background-color: var(--color-error);
}

.c-Toast--is-error .c-Toast__close {
  background-color: var(--color-error_dark);
}

.c-Toast__label {
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
}

.c-Toast__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 32px;
  height: 100%;
  border: none;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: white;
}

.c-Toast__close:hover {
  opacity: 0.8;
}

.c-Toast__close svg {
  width: 8px;
  height: 8px;
}

.c-Toast--is-visible {
  animation-name: SHOW;
  animation-duration: 0.5s;
  animation-delay: 0s;
}

@keyframes SHOW {
  0% {
    transform: translateY(90px);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.toast-link {
  color: white;
}
