.c-App {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

.c-App__error {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  flex-direction: column;
}

.c-App__error h1 {
  color: var(--color-deep_gray);
  margin: 0;
  line-height: 1.2;
  margin-bottom: 0.5em;
}

.c-App__error p {
  color: var(--color-deep_gray);
  line-height: 1.5;
  margin: 0;
  max-width: 60ch;
}

.c-App__error a {
  color: var(--color-sky_blue);
}

.c-App__error.websocket {
  text-align: left;
}

.c-App__error.websocket h1 {
  text-align: center;
}
.c-App__error.websocket p {
  margin-bottom: 16px;
  max-width: 70ch;
}
